/* eslint-disable jsx-a11y/anchor-has-content */
import * as React from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import ReactTooltip from 'react-tooltip';
import { MainType } from './Container';
import { ROUTES, PRIVILEGES } from 'shared/consts';
import { checkPrivilegeAccess } from 'shared/utils';
import CasesListTableHeader from './components/CasesListTableHeader';
import Pagination from 'Components/Pagination';

let timer;

type S = {
  params: any;
  isCasesListRolledUp: boolean;
  filters: any;
};

const CASES_TYPE_LABELS = {
  various: 'różne',
  court: 'sądowe',
  vindication: 'windykacja',
  contract: 'umowy',
  other: 'inne',
};

type P = {
  location: any;
  history: any;
  match: any;
  authorizedUser: any;
};
export default class CasesList extends React.Component<MainType & P, S> {
  constructor(props) {
    super(props);
    this.state = {
      params: this.getParamsOnInit(),
      isCasesListRolledUp: false,
      filters: {
        size: JSON.parse(sessionStorage.getItem('pageSize'))?.value ?? 10,
        page: 0,
      },
    };
  }

  triggerExpandCasesList = () => {
    this.setState({ isCasesListRolledUp: !this.state.isCasesListRolledUp });
  };

  getParamsOnInit = () => {
    if (this.props.location.search) {
      const urlSearchParams = new URLSearchParams(this.props.location.search);
      const params = {};
      urlSearchParams.forEach((value, key) => (params[key] = value));
      return {
        ...params,
        caseStatusType: undefined,
        caseType: undefined,
      };
    } else {
      return {
        caseStatusType: undefined,
        caseType: undefined,
        pageNo: 0,
        pageSize: 10,
      };
    }
  };

  async componentDidMount() {
    await this.getCases();
    await this.getCounts();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState(
        {
          params: this.getParamsOnInit(),
        },
        async () => {
          await this.getCounts();
          await this.getCases();
        },
      );
    }
  }

  isContractor = () => this.props.location.pathname === ROUTES.CASES_LIST_CONTRACTOR;

  isCustomer = () => {
    return this.props.location.pathname.includes(ROUTES.CASES_LIST_CUSTOMER_ID);
  };

  getCases = async () => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      this.updateUrlWithFilters();
      if (this.isCustomer()) {
        await this.props.getClientCasesList(this.props.match.params.id, this.state.params);
      } else {
        await this.props.getCasesList(this.isContractor() ? 'executive1' : 'executive2', this.state.params);
      }
    }, 600);
  };

  getCounts = async () => {
    if (this.isCustomer()) {
      await this.props.getClientCaseCounts(this.props.match.params.id);
    } else {
      await this.props.getCaseCounts(this.isContractor() ? 'executive1' : 'executive2');
    }
  };

  closeMenu = () => {
    if (this.props.isMenuExpanded) {
      this.props.expandMenu();
    }
  };

  changeCaseStatus = (caseType, caseStatusType) => {
    this.setState(
      {
        params: {
          ...this.state.params,
          caseStatusType,
          caseType: caseType?.toUpperCase(),
        },
      },
      () => this.getCases(),
    );
  };

  changeCaseType = (caseType) => {
    this.setState(
      {
        params: {
          ...this.state.params,
          caseStatusType: undefined,
          caseType: caseType?.toUpperCase(),
        },
      },
      () => this.getCases(),
    );
  };

  onFilterChange = (id, value) => {
    this.setState(
      {
        params: {
          ...this.state.params,
          [id]: value,
        },
      },
      () => this.getCases(),
    );
  };

  updateUrlWithFilters = () => {
    const urlSearchParams = new URLSearchParams();

    Object.keys(this.state.params).map((key) => {
      if (this.state.params[key] !== undefined) {
        urlSearchParams.set(key, this.state.params[key]);
      }
      return true;
    });

    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: urlSearchParams.toString(),
    });
  };

  goToCaseDetails = (id) => generatePath(ROUTES.CASE_DETAILS, { id });

  handleManageFavourite = async (id, favourite) => {
    const { manageFavourite } = this.props;

    manageFavourite(id, !favourite);
    await this.getCases();
  };

  handlePageOrSizeChange = (...args) => {
    const [page, size] = args;
    this.setState(
      {
        filters: {
          ...this.state.filters,
          page,
          size,
        },
        params: {
          ...this.state.params,
          pageNo: page,
          pageSize: size,
        },
      },
      async () => await this.getCases(),
    );
  };

  public render() {
    const { prevPath, caseCounts = {}, casesList } = this.props;
    const isAddCaseAccess = checkPrivilegeAccess(PRIVILEGES.CASE_ADD);
    const isCardCaseAccess = checkPrivilegeAccess(PRIVILEGES.CASE_CARD);
    const isExportAccess = checkPrivilegeAccess(PRIVILEGES.EXPORT);
    const totalPages = casesList?.totalPages;
    const isCustomerCasesList = window.location.pathname.includes('customer-cases-list');

    return (
      <div className="cases-list">
        <div className={`cases-list__summary ${this.state.isCasesListRolledUp ? 'cases-list__summary--rolled-up' : ''}`}>
          <div className="cases-list__summary--content">
            <div className="cases-list__summary__title">
              <Link to={prevPath ? prevPath : '/dashboard'}>
                <img src={GrayArrow} alt="" />
              </Link>
              <h2>Moje sprawy</h2>
            </div>
            <div className="cases-list__short-summary">
              <p>
                Sprawy <br /> w toku <span className="cases-list__short-summary--in-progress">{caseCounts['total']?.inProgress}</span>
              </p>
              <p>
                Zakończone <span className="cases-list__short-summary--done">{caseCounts['total']?.closed}</span>
              </p>
            </div>
            <div className="cases-list__cases-status">
              <p className="cases-list__cases-status--in-progress">W toku</p>
              <p className="cases-list__cases-status--all">Wszystkie</p>
              <p className="cases-list__cases-status--done">Zakończone</p>
            </div>
            <div className="cases-list__cases-types">
              <ul>
                <li className={`${this.state.params.caseType === undefined ? 'cases-list__cases-types--selected' : ''}`}>
                  <div className="caseTypeTitle" onClick={() => this.changeCaseType(undefined)}>
                    Wszystkie
                  </div>
                  <div></div>
                  <span className="container">
                    <span className="cases-list__cases-types--in-progress" onClick={() => this.changeCaseStatus(undefined, 'IN_PROGRESS')}>
                      {caseCounts['total']?.inProgress}
                    </span>
                    <span className="cases-list__cases-types--all" onClick={() => this.changeCaseStatus(undefined, undefined)}>
                      {caseCounts['total']?.total}
                    </span>
                    <span className="cases-list__cases-types--done" onClick={() => this.changeCaseStatus(undefined, 'CLOSED')}>
                      {caseCounts['total']?.closed}
                    </span>
                  </span>
                </li>
                {Object.keys(CASES_TYPE_LABELS).map((caseType) => (
                  <li key={caseType} className={`${this.state.params.caseType?.toLowerCase() === caseType ? 'cases-list__cases-types--selected' : ''}`}>
                    <div className="caseTypeTitle" onClick={() => this.changeCaseType(caseType)}>
                      {CASES_TYPE_LABELS[caseType]}
                    </div>
                    <span className="container">
                      <span className="cases-list__cases-types--in-progress" onClick={() => this.changeCaseStatus(caseType, 'IN_PROGRESS')}>
                        {caseCounts[caseType]?.inProgress}
                      </span>
                      <span className="cases-list__cases-types--all" onClick={() => this.changeCaseStatus(caseType, undefined)}>
                        {caseCounts[caseType]?.total}
                      </span>
                      <span className="cases-list__cases-types--done" onClick={() => this.changeCaseStatus(caseType, 'CLOSED')}>
                        {caseCounts[caseType]?.closed}
                      </span>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={`cases-list__expander ${this.state.isCasesListRolledUp ? 'cases-list__expander--rolled-up' : ''}`} onClick={() => this.triggerExpandCasesList()}>
            <img className={`cases-list__expander--icon ${this.state.isCasesListRolledUp ? 'cases-list__expander--icon-right' : ''}`} src={GrayArrow} alt="" />
          </div>
        </div>
        <div className={`cases-list__container cases-list__container--without-scroll ${this.state.isCasesListRolledUp ? 'cases-list__container--full-width' : ''}`}>
          <div className="cases-list__header">
            <div className="cases-list__title">
              <h2> {this.state.params.caseType ? CASES_TYPE_LABELS[this.state.params.caseType.toLowerCase()] : 'Wszystkie'}</h2>
              <Pagination pages={totalPages} getFunction={this.handlePageOrSizeChange} pageNo={this.state.filters.page} />
              {isAddCaseAccess && (
                <button className="btn btn--default" onClick={() => this.props.history.push(ROUTES.ADD_CASE)}>
                  <span>&#43;</span> Dodaj sprawę
                </button>
              )}
            </div>
          </div>
          {isExportAccess && isCustomerCasesList && (
            <div className="time-trial-list__export">
              <div onClick={() => this.props.getClientCaseXlsx(this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/')).substring(1), window.location.search)}>Eksportuj do pliku </div>
            </div>
          )}
          <div className="cases-list__table">
            <table className="table">
              <CasesListTableHeader onFilterChange={this.onFilterChange} filterValues={this.state.params} />
              <tbody>
                {casesList?.content &&
                  casesList.content.map(({ id, executive1FullName, executive2FullName, caseNumber, status, createdAt, plaintiff, defendant, signature, wps, kind, favourite, name, electronicOnly }) => (
                    <tr className="hoverable" key={caseNumber}>
                      <td className={`actions ${electronicOnly ? 'actions--electronicOnly' : ''}`}>
                        {isCardCaseAccess && <a href={this.goToCaseDetails(id)} className="btn-action btn-action--open" data-tip="Karta sprawy" />}
                        <button className={`btn-action btn-action--${favourite ? 'favourite-selected' : 'favourite'}`} data-tip={favourite ? 'Usuń z ulubionych' : `Dodaj do ulubionych`} onClick={() => this.handleManageFavourite(id, favourite)}></button>
                        <button className="btn-action btn-action--edit" data-tip="Edytuj" onClick={() => this.props.history.push(generatePath(ROUTES.EDIT_CASE, { id }))}></button>
                        <button className="btn-action btn-action--delete" data-tip="Usuń" onClick={() => this.props.showConfirmPopup('DeleteCase', { id })}></button>
                      </td>
                      <td>{caseNumber}</td>
                      <td>{name || '--'}</td>
                      <td>{executive1FullName}</td>
                      <td>{executive2FullName}</td>
                      <td>{createdAt}</td>
                      <td>{status}</td>
                      <td>{plaintiff || '--'}</td>
                      <td>{defendant || '--'}</td>
                      <td>{signature || '--'}</td>
                      <td>{wps || '--'}</td>
                      <td>{kind || '--'}</td>
                      {/* <td>{'--'}</td> */}
                      <td>
                        {
                          <a
                            className="cases-list__link"
                            href={generatePath(ROUTES.FILES, { id })}
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push(generatePath(ROUTES.FILES, { id }));
                            }}>
                            Folder
                          </a>
                        }
                      </td>
                      <td>{favourite ? 'Tak' : 'Nie'}</td>
                      <ReactTooltip place="top" effect="solid" className="tooltip" />
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
