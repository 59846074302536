/* eslint-disable jsx-a11y/anchor-has-content */
import * as React from 'react';
import { generatePath } from 'react-router';
// import { Link } from 'react-router-dom';
// import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import ReactTooltip from 'react-tooltip';
import { MainType } from './Container';
import { ROUTES } from 'shared/consts';
import CasesListTableHeader from './components/CasesListTableHeader';

type S = {
  params: any;
};

// const CASES_TYPE_LABELS = {
//   various: 'różne',
//   court: 'sądowe',
//   vindication: 'windykacja',
//   contract: 'umowy',
//   other: 'inne',
// };

type P = {
  location: any;
  history: any;
  match: any;
};
export default class FavouriteCasesList extends React.Component<MainType & P, S> {
  constructor(props) {
    super(props);
    this.state = {
      params: this.getParamsOnInit(),
    };
  }

  getParamsOnInit = () => {
    if (this.props.location.search) {
      const urlSearchParams = new URLSearchParams(this.props.location.search);
      const params = {};
      urlSearchParams.forEach((value, key) => (params[key] = value));
      return {
        ...params,
        caseStatusType: undefined,
        caseType: undefined,
        favourite: true,
      };
    } else {
      return {
        caseStatusType: undefined,
        caseType: undefined,
        pageNo: 0,
        pageSize: 10,
        favourite: true,
      };
    }
  };

  async componentDidMount() {
    // await this.getCounts();
    await this.props.getExternalUserCasesList(this.state.params);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState(
        {
          params: this.getParamsOnInit(),
        },
        async () => {
          await this.getCounts();
          await this.getCases();
        },
      );
    }
  }

  isContractor = () => this.props.location.pathname === ROUTES.CASES_LIST_CONTRACTOR;

  isCustomer = () => {
    return this.props.location.pathname.includes(ROUTES.CASES_LIST_CUSTOMER_ID);
  };

  getCases = async () => {
    this.updateUrlWithFilters();
    if (this.isCustomer()) {
      await this.props.getClientCasesList(this.props.match.params.id, this.state.params);
    } else {
      await this.props.getCasesList(this.isContractor() ? 'executive1' : 'executive2', this.state.params);
    }
  };

  getCounts = async () => {
    if (this.isCustomer()) {
      await this.props.getClientCaseCounts(this.props.match.params.id);
    } else {
      await this.props.getCaseCounts(this.isContractor() ? 'executive1' : 'executive2');
    }
  };

  closeMenu = () => {
    if (this.props.isMenuExpanded) {
      this.props.expandMenu();
    }
  };

  changeCaseStatus = (caseType, caseStatusType) => {
    this.setState(
      {
        params: {
          ...this.state.params,
          caseStatusType,
          caseType: caseType?.toUpperCase(),
        },
      },
      () => this.getCases(),
    );
  };

  changeCaseType = (caseType) => {
    this.setState(
      {
        params: {
          ...this.state.params,
          caseStatusType: undefined,
          caseType: caseType?.toUpperCase(),
        },
      },
      () => this.getCases(),
    );
  };

  onFilterChange = (id, value) => {
    this.setState(
      {
        params: {
          ...this.state.params,
          [id]: value,
        },
      },
      () => this.getCases(),
    );
  };

  updateUrlWithFilters = () => {
    const urlSearchParams = new URLSearchParams();

    Object.keys(this.state.params).map((key) => {
      if (this.state.params[key] !== undefined) {
        urlSearchParams.set(key, this.state.params[key]);
      }
      return true;
    });

    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: urlSearchParams.toString(),
    });
  };

  goToCaseDetails = (id) => generatePath(ROUTES.CASE_DETAILS, { id });

  handleManageFavourite = async (id) => {
    const { manageFavourite } = this.props;

    await manageFavourite(id, false);
    await this.props.getExternalUserCasesList(this.state.params);
  };

  public render() {
    const { favouriteCases } = this.props;

    return (
      <div className="cases-list">
        <div className="cases-list__container cases-list__container--full-width">
          <div className="cases-list__header">
            <div className="cases-list__title">
              <h2>Ulubione sprawy</h2>
            </div>
          </div>
          <div className="cases-list__table">
            <table className="table">
              <CasesListTableHeader onFilterChange={this.onFilterChange} filterValues={this.state.params} />
              <tbody>
                {favouriteCases &&
                  favouriteCases.content.map(({ id, caseNumber, company, status, createdAt, plaintiff, defendant, signature, wps, kind }) => (
                    <tr className="hoverable" key={caseNumber}>
                      <td className="actions">
                        <a href={this.goToCaseDetails(id)} className="btn-action btn-action--open" data-tip="Karta sprawy" />
                        {/* <button className="btn-action btn-action--open" data-tip="Karta sprawy" onClick={() => this.goToCaseDetails(id)}></button> */}
                        <button className={`btn-action btn-action--favourite-selected`} data-tip={'Usuń z ulubionych'} onClick={() => this.handleManageFavourite(id)}></button>
                      </td>
                      <td>{caseNumber}</td>
                      <td>{createdAt}</td>
                      <td>{company}</td>
                      <td>{status}</td>
                      <td>{plaintiff || '--'}</td>
                      <td>{defendant || '--'}</td>
                      <td>{signature || '--'}</td>
                      <td>{wps || '--'}</td>
                      <td>{kind || '--'}</td>
                      <td>{'--'}</td>
                      <td>{'--'}</td>
                      <ReactTooltip place="top" effect="solid" className="tooltip" />
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
